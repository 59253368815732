#map {
  width: 100%;
  height: 100%;
  min-height: 480px;
  margin: auto;
}
#item-map {
  width: 100%;
  height: 100%;
  min-height: 480px;
  margin: auto;
}